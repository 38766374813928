<template>
    <a-drawer
        title="Добавить новость"
        placement="right"
        :width="isMobile ? '100%' : 700"
        class="news_drawer"
        :visible="visible"
        :zIndex="9999"
        :after-visible-change="afterVisibleChange"
        @close="visible = false">
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules">
            <a-form-model-item 
                ref="title" 
                label="Название" 
                prop="title">
                <a-input v-model="form.title" size="large" />
            </a-form-model-item>
            <a-form-model-item 
                ref="content" 
                label="Описание" 
                prop="content">
                <component
                    :is="ckEditor"
                    :key="edit || visible"
                    v-model="form.content" />
            </a-form-model-item>
            <a-form-model-item 
                ref="is_important" 
                label="Важная новость" 
                prop="is_important">
                <a-switch v-model="form.is_important" />
            </a-form-model-item>
            <a-button type="primary" size="large" :loading="loading" @click="onSubmit()">
                Сохранить
            </a-button>
        </a-form-model>
    </a-drawer>
</template>

<script>
export default {
    props: {
        resetList: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        ckEditor() {
            if(this.visible)
                return () => import('@apps/CKEditor')
            else
                return null
        }
    },
    data() {
        return {
            visible: false,
            edit: false,
            loading: false,
            form: {
                title: '',
                content: '',
                is_important: false,
                related_object: null,
                image: null,
                files: []
            },
            rules: {
                name: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ],
                content: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        afterVisibleChange(vis) {
            if(!vis) {
                this.edit = false
                this.form = {
                    title: '',
                    content: '',
                    is_important: false,
                    related_object: null,
                    image: null,
                    files: []
                }
            }
        },
        openDrawer() {
            this.visible = true
        },
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    const formData = {...this.form}
                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/news/news/${form.id}/update/`, formData)
                            if(data) {
                                this.resetList()
                                this.visible = false
                                this.$message.info('Новость обновлена')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания')
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/news/news/create/', formData)
                            if(data) {
                                this.resetList()
                                this.visible = false
                                this.$message.info('Новость создана')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания')
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>